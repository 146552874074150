import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const LoginBg = ({ children }) => {
  const sceneRef = useRef(null);
  const requestRef = useRef();
  const mouseX = useRef(0);
  const mouseY = useRef(0);

  useEffect(() => {
    let scene, camera, renderer, torus, particlesMesh, clock;

    const initialize = () => {
      // Scene
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0x223646);

      // Camera
      const innerWidth = window.innerWidth;
      const innerHeight = window.innerHeight;
      camera = new THREE.PerspectiveCamera(
        75,
        innerWidth / innerHeight,
        0.1,
        1000
      );
      camera.position.z = 2;

      // Renderer
      renderer = new THREE.WebGLRenderer();
      renderer.setSize(innerWidth, innerHeight);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

      sceneRef.current.appendChild(renderer.domElement);

      // Torus
      const geometry = new THREE.TorusGeometry(0.7, 0.2, 16, 100);
      const material = new THREE.PointsMaterial({ size: 0.005 });
      torus = new THREE.Points(geometry, material);

      // Particles
      const particlesGeometry = new THREE.BufferGeometry();
      const particlesCount = 5000;
      const positionArray = new Float32Array(particlesCount * 3);

      for (let i = 0; i < particlesCount * 3; i++) {
        positionArray[i] = (Math.random() - 0.5) * (Math.random() * 5);
      }

      particlesGeometry.setAttribute(
        "position",
        new THREE.BufferAttribute(positionArray, 3)
      );

      const particlesMaterial = new THREE.PointsMaterial({
        size: 0.005,
        color: 0xff74af,
      });

      particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);

      scene.add(torus, particlesMesh);

      // Mouse
      document.addEventListener("mousemove", animateParticles);

      // Clock
      clock = new THREE.Clock();

      // Animation
      animate();
    };

    const animateParticles = (event) => {
      mouseX.current = event.clientX;
      mouseY.current = event.clientY;
    };

    const animate = () => {
      const elapsedTime = clock.getElapsedTime();

      torus.rotation.y = -0.5 * elapsedTime;
      particlesMesh.rotation.x += 0.001;

      if (mouseX.current > 0) {
        particlesMesh.rotation.x = -mouseY.current * (elapsedTime * 0.00008);
        particlesMesh.rotation.y = -mouseX.current * (elapsedTime * 0.00008);
      }

      renderer.render(scene, camera);
      requestRef.current = requestAnimationFrame(animate);
    };

    initialize();

    return () => {
      document.removeEventListener("mousemove", animateParticles);
      cancelAnimationFrame(requestRef.current);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundColor: "rgba(0,0,0,0.5)",
          width: "100%",
        }}
        ref={sceneRef}
      >
        <Box class=""></Box>
      </Box>
      {children}
    </>
  );
};

export default LoginBg;
